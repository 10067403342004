import React from "react"

import BriefIntroSection from "../BriefIntro/BriefIntroSection"
import AboutSection from "./AboutSection"
import ContactSection from "./ContactSection"
import InstagramSection from "./InstagramSection"

import "./AboutContact.scss"

const AboutContactInfo = () => {
  return (
    <section className="about-contact">
      <BriefIntroSection />
      <AboutSection />
      <ContactSection />
      <InstagramSection />
    </section>
  )
}

export default AboutContactInfo
