import React from "react"

import SEO from "../components/seo"

import AboutContactInfo from "../components/AboutContact/AboutContactInfo"

const About = () => {
  return (
    <>
      <SEO path="/about" title="About - Jessica Coppet" />
      <AboutContactInfo />
    </>
  )
}

export default About
