import React from "react"

import "./InstagramSection.scss"

const InstagramSection = () => {
  const [posts, setPosts] = React.useState([])

  React.useEffect(() => {
    fetch("https://www.instagram.com/jessicacoppetillustration/?__a=1")
      .then((response) => response.json())
      .then((data) => {
        setPosts([...data.graphql.user.edge_owner_to_timeline_media.edges])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <section className="instagram-section">
      <div className="instagram-section__container">
        <div className="instagram-section__container__title">
          Jessica’s Instagram
        </div>
        <div className="instagram-section__divider" />
        <div className="instagram-section__images-container">
          {posts.map((post) => (
            <img
              key={post.node.id}
              className="instagram-section__images-container__image"
              src={post.node.thumbnail_src}
              loading="lazy"
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default InstagramSection
