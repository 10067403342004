import React from "react"

import "./AboutSection.scss"
const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="about-section__container">
        <div className="about-section__container__image__border">
          <div className="about-section__container__image__border__outer">
            <div className="about-section__container__image__border__inner">
              <div className="about-section__container__image__border__inner__image" />
            </div>
          </div>
        </div>
        <div className="about-section__container__text">
          <div className="about-section__container__text__heading">
            About Jessica
          </div>
          <div className="about-section__container__divider" />
          <div className="about-section__container__text__paragraph">
            I’m Jessica Coppet, a French Graphic Designer specialising in
            digital illustration. I grew up in Martinique and have lived in many
            different countries. Some of the cities, I’ve lived in include
            Paris, New York and Melbourne.
            <br />
            <br />
            My travels around the world eventually led me to my passion for
            illustration, with an innate desire to become a professional in a
            creative ﬁeld. I remember being a teenager and having a dream to
            become a fashion designer.
            <br />
            <br />
            So, I started to take fashion design classes around the age of 17
            while I was in high school. I then went on to study Fine Art at the
            Art Student League of New York, specialising in abstract painting
            and mixed media.
            <br />
            <br />
            My adventurous spirit then took me to Melbourne where I studied to
            become a Graphic Designer. After my studies I decided to specialise
            in Digital illustration and start my own illustration business. I
            now work from my home studio as a full-time illustrator. I work on
            private commissions and commercial illustrations.
            <br />
            <br />
            I sell prints of my work online, as well as attending artist markets
            as a stall holder and organising exhibitions.
            <br />
            <br />I have created a unique style, focusing on creative scribble
            portraits. My main medium is digital, I draw with photoshop using a
            Wacom Cintiq Pro 24’’ Touch. I engage a line art technique to create
            realistic portraits in a highly creative and abstract way. Facial
            Expression and Anatomy have become a focal point for my art. My
            philosophical commitment to Female Empowerment and Body Positivity
            has found a true expression in my art.
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
