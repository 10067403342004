import React from "react"

import Link from "../../utils/link"

import FirstBriefIntroImage from "./images/FirstBriefIntroImage"
import FirstBriefIntroImageMobile from "./images/FirstBriefIntroImageMobile"
import SecondBriefIntroImage from "./images/SecondBriefIntroImage"
import SecondBriefIntroImageMobile from "./images/SecondBriefIntroImageMobile"
import ThirdBriefIntroImage from "./images/ThirdBriefIntroImage"
import FourthBriefIntroImage from "./images/FourthBriefIntroImage"
import Arrow from "../../assets/images/icons/arrow_intro.svg"

import "./BriefIntroSection.scss"

const BriefIntroSection = () => {
  return (
    <section className="brief-intro-section">
      <div className="brief-intro-section__container">
        <Link
          to="/shop/mothers-love-giclee-print-fine-art-print-line-art-art-print-home-decor-pen-drawing-women-art-mothers-day-motherhood/"
          className="brief-intro-section__container__one"
        >
          <div className="brief-intro-section__container__one__text">
            JESSICA <span>CoPPET</span>
          </div>
          <div className="brief-intro-section__container__one__image">
            <FirstBriefIntroImage />
          </div>
          <img
            className="brief-intro-section__container__one__arrow"
            src={Arrow}
          />
          <span className="brief-intro-section__container__one__description">
            HANDS, 2020
          </span>

          <div className="brief-intro-section__container__one__divider" />
        </Link>
        <Link
          to="/shop/mothers-love-giclee-print-fine-art-print-line-art-art-print-home-decor-pen-drawing-women-art-mothers-day-motherhood/"
          className="brief-intro-section__container__one__mobile"
        >
          <div className="brief-intro-section__container__one__mobile__image">
            <FirstBriefIntroImageMobile />
          </div>
          <div className="brief-intro-section__container__one__mobile__text">
            CoPPET
          </div>
          <img
            className="brief-intro-section__container__one__mobile__arrow"
            src={Arrow}
          />
          <span className="brief-intro-section__container__one__mobile__description">
            HANDS, 2020
          </span>
          <div className="brief-intro-section__container__one__mobile__divider" />
        </Link>
        <Link
          to="/shop/frida-kahlo-giclee-print-fine-art-print-print-artwork-pop-art-unique-art-women-art-line-art-portrait-art-art-print/"
          className="brief-intro-section__container__two"
        >
          <div className="brief-intro-section__container__two__divider" />
          <span className="brief-intro-section__container__two__description">
            FRIDA, 2020
          </span>
          <img
            className="brief-intro-section__container__two__arrow"
            src={Arrow}
          />
          <div className="brief-intro-section__container__two__text">
            IS A FrENCH <span>Melbournian</span>
          </div>
          <div className="brief-intro-section__container__two__image">
            <SecondBriefIntroImage />
          </div>
        </Link>
        <Link
          to="/shop/frida-kahlo-giclee-print-fine-art-print-print-artwork-pop-art-unique-art-women-art-line-art-portrait-art-art-print/"
          className="brief-intro-section__container__two__mobile"
        >
          <div className="brief-intro-section__container__two__mobile__divider" />
          <span className="brief-intro-section__container__two__mobile__description">
            FRIDA, 2020
          </span>
          <img
            className="brief-intro-section__container__two__mobile__arrow"
            src={Arrow}
          />
          <div className="brief-intro-section__container__two__mobile__text">
            Melbournian
          </div>
        </Link>
        <Link
          to="/shop/dark-beauty-giclee-print-fine-art-print-afro-art-illustration-home-decor-art-print-modern-art-line-art-african-art/"
          className="brief-intro-section__container__three"
        >
          <div className="brief-intro-section__container__three__image one">
            <ThirdBriefIntroImage />
          </div>
          <div className="brief-intro-section__container__three__text">
            GRAPHIC <span>DESIGNER</span>
          </div>
          <div className="brief-intro-section__container__three__image two">
            <ThirdBriefIntroImage />
          </div>
          <img
            className="brief-intro-section__container__three__arrow"
            src={Arrow}
          />
          <span className="brief-intro-section__container__three__description">
            TINA, 2020
          </span>
          <div className="brief-intro-section__container__three__divider" />
        </Link>
        <Link
          to="/shop/dark-beauty-giclee-print-fine-art-print-afro-art-illustration-home-decor-art-print-modern-art-line-art-african-art/"
          className="brief-intro-section__container__three__mobile"
        >
          <div className="brief-intro-section__container__three__mobile__image">
            <SecondBriefIntroImageMobile />
          </div>
          <div className="brief-intro-section__container__three__mobile__text">
            DESIGNER
          </div>
          <img
            className="brief-intro-section__container__three__mobile__arrow"
            src={Arrow}
          />
          <span className="brief-intro-section__container__three__mobile__description">
            TINA, 2020
          </span>
          <div className="brief-intro-section__container__three__mobile__divider" />
        </Link>
        <Link
          to="/shop/amy-winehouse-giclee-print-fine-art-print-celebrity-portrait-home-decor-illustration-modern-art-poster-art-line-drawing/"
          className="brief-intro-section__container__four"
        >
          <div className="brief-intro-section__container__four__text">
            SPECIAlISING <span>IN</span>
          </div>
          <div className="brief-intro-section__container__four__image">
            <FourthBriefIntroImage />
          </div>
          <img
            className="brief-intro-section__container__four__arrow"
            src={Arrow}
          />
          <span className="brief-intro-section__container__four__description">
            DARK BEAUTY, 2020
          </span>
          <div className="brief-intro-section__container__four__divider" />
        </Link>
        <Link
          to="/shop/mothers-love-giclee-print-fine-art-print-line-art-art-print-home-decor-pen-drawing-women-art-mothers-day-motherhood/"
          className="brief-intro-section__container__five"
        >
          <div className="brief-intro-section__container__five__divider" />
          <span className="brief-intro-section__container__five__description">
            HANDS, 2020
          </span>
          <img
            className="brief-intro-section__container__five__arrow"
            src={Arrow}
          />
          <div className="brief-intro-section__container__five__image one">
            <FirstBriefIntroImage />
          </div>
          <div className="brief-intro-section__container__five__text">
            <span className="mobile">in</span> DIGITAL
            <span> ilLuSTRAtION.</span>
          </div>
          <div className="brief-intro-section__container__five__image two">
            <FirstBriefIntroImage />
          </div>
        </Link>
        <Link
          to="/shop/mothers-love-giclee-print-fine-art-print-line-art-art-print-home-decor-pen-drawing-women-art-mothers-day-motherhood/"
          className="brief-intro-section__container__five__mobile"
        >
          <div className="brief-intro-section__container__five__mobile__divider" />
          <span className="brief-intro-section__container__five__mobile__description">
            HANDS, 2020
          </span>
          <img
            className="brief-intro-section__container__five__mobile__arrow"
            src={Arrow}
          />
          <div className="brief-intro-section__container__five__mobile__text">
            IlLuSTRAtION.
          </div>
        </Link>
      </div>
    </section>
  )
}

export default BriefIntroSection
