import React from "react"
import autosize from "autosize"

import Link from "../../utils/link"

import EtsyLogo from "../../assets/images/icons/etsy-dark.svg"
import FacebookLogo from "../../assets/images/icons/facebook-dark.svg"
import InstagramLogo from "../../assets/images/icons/instagram-dark.svg"
import PinterestLogo from "../../assets/images/icons/pinterest-dark.svg"
import EnvelopeLogo from "../../assets/images/icons/envelope-dark.svg"

import "./ContactSection.scss"

const ContactSection = () => {
  React.useEffect(() => {
    autosize(document.querySelectorAll("textarea"))
  }, [])

  return (
    <section className="contact-section">
      <div className="contact-section__container">
        <div className="contact-section__container__heading">Contact</div>
        <div className="contact-section__divider" />
        <div className="contact-section__container__border__inner__container">
          <div className="contact-section__container__border__inner__container__text">
            <div className="contact-section__container__border__inner__container__text__sub-text">
              Reach out to Jessica for questions, quotes, custom commissions –
              or just to say hello!
            </div>
            <div className="contact-section__container__border__inner__container__text-two">
              <div className="contact-section__container__border__inner__container__text__mail">
                <Link to="mailto:hello@jessicacoppet.com">
                  hello@jessicacoppet.com
                </Link>
              </div>
              <div className="contact-section__container__border__inner__container__text__divider" />
              <div className="contact-section__container__border__inner__container__text__social">
                <Link to="https://www.etsy.com/in-en/shop/JessicaCoppetDesign">
                  <img src={EtsyLogo} alt="Etsy" />
                </Link>
                <Link to="https://www.facebook.com/jessicacoppetillustration/">
                  <img src={FacebookLogo} alt="Facebook" />
                </Link>
                <Link to="https://www.instagram.com/jessicacoppetillustration/">
                  <img src={InstagramLogo} alt="Instagram" />
                </Link>
                <Link to="https://www.pinterest.com.au/jessicacoppet/">
                  <img src={PinterestLogo} alt="Pinterest" />
                </Link>
                <Link to="mailto:hello@jessicacoppet.com">
                  <img src={EnvelopeLogo} alt="Email" />
                </Link>
              </div>
            </div>
          </div>
          <div className="contact-section__container__border__inner__container__form">
            <form className="form">
              <input
                className="form__input"
                type="text"
                placeholder="Your Name"
                required
              />
              <input
                className="form__input"
                type="email"
                placeholder="Your Email"
                required
              />
              <input
                className="form__input"
                type="text"
                placeholder="Subject"
                required
              />
              <textarea
                className="form__input__enquiry"
                placeholder="Your Enquiry"
                required
              />
              <button className="form__submit" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactSection
